import { action, observable, computed, makeObservable } from 'mobx';

import { IRootStore } from '@stores/index';
import { apiAuthCaller } from '@services/apiCaller';
import { getApiErrorMessage } from '@components/common/util/util';

export enum AccountType {
  SOURCE = 'source',
  DESTINATION = 'destination',
  WHT = 'wht',
}

interface AccountCreatePayload {
  accountId?: string;
  accountInfo: {
    name?: string;
    options?: Record<string, unknown>;
    secret?: Record<string, unknown>;
    secretUpdated?: Record<string, unknown>;
  };
  role: string;
}

export interface IAccount {
  id: string;
  userId: string;
  role: string;
  skipValidation?: boolean;
  createdAt: string;
  updatedAt: string;
  name: string;
  options?: Record<string, unknown>;
  metadata?: Record<string, unknown>;
  workspaceId: string;
  rudderCategory?: string;
}

export interface IAccountsStore {
  accounts: IAccount[];
  fetchAudienceAccounts: (roles: string) => Promise<void>;
  fetchById: <T = IAccount>(accountId: string) => Promise<T | undefined>;
  findAccountById: (id: string) => IAccount | undefined;
  createAccount: (payload: AccountCreatePayload) => Promise<IAccount | undefined>;
  countByRole: Record<string, number>;
}

export class AccountsStore implements IAccountsStore {
  @observable accounts: IAccount[];

  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.accounts = [];
  }

  @action.bound
  public async fetchAudienceAccounts(roles: string) {
    const { messagesStore } = this.rootStore;

    try {
      const [sourceAccounts, whtAccounts] = await Promise.all([
        apiAuthCaller().get<IAccount[]>(`/accounts`, {
          params: { roles, rudderCategory: AccountType.SOURCE },
        }),
        apiAuthCaller().get<IAccount[]>(`/accounts`, {
          params: { roles, rudderCategory: AccountType.WHT },
        }),
      ]);

      this.accounts = [...sourceAccounts.data, ...whtAccounts.data];
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to fetch accounts'));
    }
  }

  @action.bound
  public async fetchById<T = IAccount>(accountId: string) {
    const { messagesStore } = this.rootStore;

    try {
      const response = await apiAuthCaller().get<T>(`/accounts/${accountId}`);

      return response.data;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to fetch accounts'));
      return undefined;
    }
  }

  @action.bound
  public async createAccount(payload: AccountCreatePayload) {
    const { messagesStore } = this.rootStore;

    try {
      const response = await apiAuthCaller().post<IAccount>('/warehouseSources/accounts', payload);

      this.accounts = [...this.accounts, response.data];

      return response.data;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to create account'));
      return undefined;
    }
  }

  @computed
  public get countByRole() {
    return this.accounts.reduce((accumulator: Record<string, number>, account) => {
      const prevCount = accumulator[account.role] || 0;
      accumulator[account.role] = prevCount + 1;
      return accumulator;
    }, {});
  }

  public findAccountById(accountId: string) {
    return this.accounts.find(({ id }) => id === accountId);
  }
}
