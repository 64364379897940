import { action, computed, makeObservable, observable } from 'mobx';

import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IConnectionsStore } from '@stores/connections';
import { IDestinationsListStore } from '@stores/destinationsList';
import { IMessageStore } from '@stores/messages';
import { RetlConnectionListStore } from '@stores/retlConnections';
import { ISourceStore, SourceStore } from '@stores/source';
import { ISourcesListStore } from '@stores/sourcesList';
import { ITrackingPlanListStore } from '@stores/trackingPlans/trackingPlansList';
import { IUserStore } from '@stores/user';
import { IWorkspaceStore } from '@stores/workspace';
import { assertNonNullish } from '@utils/assert';
import { ISourceDefinitionsListStore } from '../sourceDefinitionsList';
import { ConfigType, RETLSource, RETLSourceType, UpdateRETLSourceRequest } from './types';
import { WarehouseSourceOrigin } from '@stores/types';

type RootStore = {
  connectionsStore: IConnectionsStore;
  destinationsListStore: IDestinationsListStore;
  userStore: IUserStore;
  messagesStore: IMessageStore;
  sourceDefinitionsListStore: ISourceDefinitionsListStore;
  sourcesListStore: ISourcesListStore;
  workspaceStore: IWorkspaceStore;
  trackingPlanListStore: ITrackingPlanListStore;
  retlConnectionListStore: RetlConnectionListStore;
};

export class RetlSourceStore<T extends RETLSourceType> {
  rootStore: RootStore;

  @observable id: string;

  @observable name: string;

  @observable public createdAt: string;

  @observable oldSource: ISourceStore;

  @observable public updatedAt: string;

  @observable config: ConfigType<T>;

  @observable enabled: boolean;

  @observable accountId: string;

  @observable writeKey: string;

  @observable sourceType: T;

  @observable destinationIds: string[] = [];

  @observable sourceDefinitionName: string;

  @observable primaryKey: string | undefined;

  @computed get sourceDef() {
    return this.rootStore.sourceDefinitionsListStore.getByName(this.sourceDefinitionName)!;
  }

  constructOldSource() {
    const data = {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      writeKey: this.writeKey,
      enabled: this.enabled,
      isGeoEnrichmentEnabled: false,
      config: {
        origin: this.sourceType as unknown as WarehouseSourceOrigin,
        ...this.config,
        accountId: this.accountId,
      },
      secretConfig: {},
      sourceDefinitionId: this.sourceDef.id,
      transient: false,
      trackingPlanConfig: undefined,
    };
    return new SourceStore(data, this.rootStore);
  }

  @computed get category() {
    return this.sourceDef?.category;
  }

  @computed get destinations() {
    return this.destinationIds.map((id) => {
      const dest = this.rootStore.destinationsListStore.destinationById(id);
      assertNonNullish(dest, `Invalid destination id ${id}`);
      return dest;
    });
  }

  constructor(retlSource: RETLSource<T>, rootStore: RootStore) {
    this.rootStore = rootStore;
    this.id = retlSource.id;
    this.name = retlSource.name;
    this.createdAt = retlSource.createdAt;
    this.updatedAt = retlSource.updatedAt;
    this.config = retlSource.config;
    this.enabled = retlSource.enabled;
    this.accountId = retlSource.accountId;
    this.writeKey = retlSource.writeKey;
    this.sourceType = retlSource.sourceType;
    this.sourceDefinitionName = retlSource.sourceDefinitionName;
    this.primaryKey = retlSource.primaryKey;
    this.oldSource = this.constructOldSource();
    makeObservable(this);
  }

  @action.bound
  updateDestinationIds(destinationsIds: string[]) {
    this.destinationIds = destinationsIds;
  }

  @action.bound
  public async update(
    updates: UpdateRETLSourceRequest<T>,
    errorMessage?: string,
  ): Promise<boolean> {
    const { messagesStore } = this.rootStore;
    try {
      const { config, accountId, enabled, name, primaryKey } = updates;

      const payload = {
        sourceType: this.sourceType,
        ...(name !== undefined && { name }),
        ...(enabled !== undefined && { enabled }),
        ...(accountId !== undefined && { accountId }),
        ...(config !== undefined && {
          config: { ...this.config, ...config },
          ...(primaryKey !== undefined && { primaryKey }),
        }),
      };

      const { data } = await apiAuthCaller().put<RETLSource<T>>(
        `/retl-sources/${this.id}`,
        payload,
      );
      this.name = data.name;
      this.config = data.config;
      this.accountId = data.accountId;
      this.enabled = data.enabled;
      this.primaryKey = data.primaryKey;
      this.oldSource = this.constructOldSource();

      return true;
    } catch (err) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, errorMessage || 'Failed to update the rETL source'),
      );
    }
    return false;
  }

  @computed
  get sourceDefinition() {
    const { sourceDefinitionsListStore } = this.rootStore;
    const sourceDef = sourceDefinitionsListStore.getByName(this.sourceDefinitionName);
    assertNonNullish(sourceDef, 'Invalid source definition');
    return sourceDef;
  }
}
