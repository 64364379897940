import React, { lazy, Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import useStores from '@stores/useStores';
import { isConnectionAvailable } from './utils';
import { StretchLayout } from './styles';
import { CreateTPMode } from '@components/trackingPlans/createTrackingPlans/types';
import { with404Redirect } from '@utils/hoc/with404Redirect';
import { RedirectRoute } from './redirect';
import { experiment, FeatureFlagKeys } from '@lib/experiment';

const Connections = lazy(() =>
  import(/* webpackChunkName: "connections" */ '@components/connections').then((module) => ({
    default: module.Connections,
  })),
);

const Canny = lazy(() => import(/* webpackChunkName: "auth.canny" */ '@auth/canny'));
const GetStarted = lazy(
  () => import(/* webpackChunkName: "quickstart" */ '@components/quickstart'),
);
const DestinationDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "destinations.destination-view" */ '@components/destinations/destination-view'
    ),
);
const Invoices = lazy(
  () => import(/* webpackChunkName: "invoices" */ '@components/profile/invoices'),
);
const Destinations = lazy(
  () =>
    import(
      /* webpackChunkName: "destinations.destination-list" */ '@components/destinations/destination-list'
    ),
);
const TransformationsPage = lazy(
  () => import(/* webpackChunkName: "transformations" */ '@components/transformations'),
);
const TransformationDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "transformations.transformationDetails" */ '@components/transformations/transformationDetails'
    ),
);
const AddTransformation = lazy(
  () =>
    import(
      /* webpackChunkName: "transformations.addTransformation" */ '@components/transformations/addTranformation'
    ),
);
const LibraryDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "transformations.libraries.libraryDetails" */ '@components/transformations/libraries/libraryDetails'
    ),
);
const AddLibrary = lazy(
  () =>
    import(
      /* webpackChunkName: "transformations.libraries.addLibrary" */ '@components/transformations/libraries/addLibrary'
    ),
);

const Profiles = lazy(
  () => import(/* webpackChunkName: "profiles" */ '@components/profiles/routes'),
);
const RETLWorkflowRoutes = lazy(
  () => import(/* webpackChunkName: "RETLWorkflowRoutes" */ '@components/retlWorkflow/routes'),
);

const AudienceCohort = lazy(() =>
  import(/* webpackChunkName: "AudienceCohortRoutes" */ '@components/cohorts/routes').then(
    (module) => ({
      default: module.AudienceCohortRoutes,
    }),
  ),
);

const Directory = lazy(() => import(/* webpackChunkName: "directory" */ '@components/directory'));
const Syncs = lazy(() => import(/* webpackChunkName: "syncs" */ '@components/syncs'));
const Profile = lazy(() => import(/* webpackChunkName: "profile" */ '@components/profile/profile'));
const Workspace = lazy(
  () => import(/* webpackChunkName: "workspace" */ '@components/profile/workspace'),
);
const Organization = lazy(
  () => import(/* webpackChunkName: "organization" */ '@components/profile/organization'),
);
const StatusesView = lazy(
  () =>
    import(/* webpackChunkName: "destinations.liveEvents" */ '@components/destinations/liveEvents'),
);
const EventsView = lazy(
  () => import(/* webpackChunkName: "sources.liveEvents" */ '@components/sources/liveEvents'),
);
const IntroPage = lazy(
  () =>
    import(
      /* webpackChunkName: "liveEvents.introPage" */ '@components/common/liveEvents/introPage'
    ),
);
const EventTransformStatusesView = lazy(
  () =>
    import(
      /* webpackChunkName: "transformations.liveEvents" */ '@components/transformations/liveEvents'
    ),
);
const SyncDetail = lazy(
  () => import(/* webpackChunkName: "syncs.syncDetails" */ '@components/syncs/syncDetail'),
);
const CloneDestination = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows.destination.clone" */ '@components/workflows/destination/clone/clone'
    ),
);
const SourceViewComponent = lazy(
  () => import(/* webpackChunkName: "sources.source-view" */ '@components/sources/source-view'),
);
const SourcesListComponent = lazy(
  () => import(/* webpackChunkName: "sources.source-list" */ '@components/sources/source-list'),
);
const Models = lazy(
  () => import(/* webpackChunkName: "models.modelsList" */ '@components/models/modelsList'),
);
const CreateDestination = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows.destination.create" */ '@components/workflows/destination/create'
    ),
);
const ConnectNewSource = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows.destination.create.connectNewSource" */ '@components/workflows/destination/connectNewSource'
    ),
);
const AddNewDestinationRegion = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows.destination.create.addRegionConfig" */ '@components/workflows/destination/addRegionConfig'
    ),
);
const CreateSource = lazy(
  () =>
    import(/* webpackChunkName: "workflows.source.create" */ '@components/workflows/source/create'),
);
const ConnectExistingSource = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows.destination.connectExistingSource" */ '@components/workflows/destination/connectExistingSource'
    ),
);
const ConnectExistingDestination = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows.source.connectExistingDestination" */ '@components/workflows/source/connectExistingDestination'
    ),
);
const ConnectNewDestination = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows.connectNewDestination.trackingPlanList" */ '@components/workflows/source/connectNewDestination'
    ),
);
const TrackingPlanListComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.trackingPlanList" */ '@components/trackingPlans/trackingPlanList'
    ),
);
const TrackingPlanViewComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.trackingPlanView" */ '@components/trackingPlans/trackingPlanView'
    ),
);
const LinkSourceComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.linkSource" */ '@components/trackingPlans/linkSource'
    ),
);
const LinkTrackingPlanComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.linkTrackingPlan" */ '@components/trackingPlans/linkTrackingPlan'
    ),
);

const Audiences = lazy(
  () =>
    import(/* webpackChunkName: "audiences.audiencesList" */ '@components/audiences/audiencesList'),
);

const AudienceConnectDestination = lazy(
  () =>
    import(/* webpackChunkName: "workflows.audience.connect" */ '@components/workflows/audiences'),
);
const HealthDashboard = lazy(
  () => import(/* webpackChunkName: "eventMetrics.eventMetrics" */ '@components/healthDashboard'),
);
const DestinationMappingConfig = lazy(
  () =>
    import(
      /* webpackChunkName: "destinations.destination-view.configurationV2.mappingComponents.setUpMapping" */ '@components/destinations/destination-view/configurationV2/mappingComponents'
    ),
);

const DataCatalog = lazy(
  () => import(/* webpackChunkName: "dataCatalog" */ '@components/dataCatalog'),
);

const chooseTrackngPlan = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.createTrackingPlans.chooseTrackingPlan" */ '@components/trackingPlans/createTrackingPlans/chooseTrackingPlan'
    ),
);

const createTrackingPlans = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.createTrackingPlans" */ '@components/trackingPlans/createTrackingPlans'
    ),
);

const RetlConnectionDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "connections.connectionDetails" */ '@components/connections/connectionView'
    ),
);

export const Path = {
  CREATE_DESTINATION: '/addDestination',
  CONNECT_EXISTING_SOURCE: (id: string) => `/destinations/${id}/connect`,
  CONNECT_EXISTING_DESTINATION: (id: string) => `/sources/${id}/connect`,
  CONNECT_NEW_DESTINATION: (id: string) => `/sources/${id}/connectNewDestination`,
  CREATE_SOURCE: '/addSource',
  CONNECT_NEW_SOURCE: (destinationId: string) => `/destinations/${destinationId}/connectNewSource`,
  CLONE_DESTINATION: (destinationId: string) => `/destinations/${destinationId}/clone`,
  ADD_NEW_DESTINATION_REGION: (destinationId: string) => `/destinations/${destinationId}/addRegion`,
  HOME: '/',
  GET_STARTED: '/getStarted',
  PROFILE: '/profile',
  WORKSPACE: '/workspace',
  ORGANIZATION: '/organization',
  SOURCES: '/sources',
  SOURCE_DETAILS: (id = ':id') => `${Path.SOURCES}/${id}`,
  DESTINATIONS: '/destinations',
  DESTINATION_DETAILS: (id = ':id') => `${Path.DESTINATIONS}/${id}`,
  DESTINATION_MAPPING_CONFIG: (id = ':id') => `${Path.DESTINATIONS}/${id}/mapping`,
  SYNCS: '/syncs',
  MODELS: '/models',
  MODEL_DETAILS: (id = ':id') => `${Path.MODELS}/${id}`,
  MODELS_SETUP: '/modelsSetup',
  AUDIENCES: '/audiences',
  AUDIENCE_SETUP: '/audiencesSetup',
  AUDIENCE_DETAILS: (id = ':id') => `${Path.AUDIENCES}/${id}`,
  AUDIENCE_CONNECT: (id = ':id') => `/audiences/${id}/connect`,
  PROFILES: '/profiles',
  PROFILES_SETUP: '/profiles/setup',
  PROFILES_CONVERT: (id: string) => `/profiles/${id}/convert`,
  PROFILES_EDIT: (id: string) => `/profiles/${id}/edit`,
  PROFILES_OPEN_ENTITY: ({ profileId, entity }: { profileId: string; entity: string }) =>
    `/profiles/${profileId}/entities/${entity}`,
  PROFILES_OPEN: (id: string) => `/profiles/${id}`,
  PROFILES_OPEN_ENTITY_COHORT: (id: string, entityId: string, cohortId: string) =>
    `/profiles/${id}/entities/${entityId}/cohorts/${cohortId}`,
  PROFILES_ACTIVATION: (id: string, entityId: string, cohortId: string) =>
    `/profiles/${id}/entities/${entityId}/cohorts/${cohortId}/activate`,
  COHORT_AUDIENCES: '/audiences-v2',
  COHORT_AUDIENCE_SETUP: () => `${Path.COHORT_AUDIENCES}/setup`,
  COHORT_AUDIENCE_DETAILS: (id: string) => `${Path.COHORT_AUDIENCES}/${id}`,
  PROFILES_ACTIVATION_SOURCE: (id: string, entityId: string, cohortId: string, sourceId: string) =>
    `/profiles/${id}/entities/${entityId}/cohorts/${cohortId}/sources/${sourceId}`,
  DIRECTORY: '/directory',
  TRANSFORMATIONS: '/transformations',
  TRANSFORMATION_DETAILS: (id: string) => `${Path.TRANSFORMATIONS}/${id}`,
  ADD_TRANSFORMATION: '/transformations/add',
  TRACKING_PLANS: '/trackingPlans',
  SETUP_TRACKING_PLAN: '/trackingPlans/setup',
  TRACKING_PLAN_DETAILS: (id: string) => `${Path.TRACKING_PLANS}/${id}`,
  LINK_NEW_SOURCE: (id: string) => `${Path.TRACKING_PLANS}/${id}/linkSource`,
  LINK_TRACKING_PLAN: (id: string) => `/sources/${id}/linkTrackingPlan`,
  TRACKING_PLANS_SETUP: (mode: CreateTPMode | ':mode') => `${Path.TRACKING_PLANS}/setup/${mode}`,
  LOGIN: '/login',
  SIGNUP: '/signup',
  CANNY: '/canny',
  FORGOT_PASSWORD: '/forgotPassword',
  SSO: '/sso',
  HEALTH_DASHBOARD: '/healthDashboard',
  INVOICES: '/invoices',
  SELECT_ORGANIZATION: '/selectOrganization',
  DATA_CATALOG: '/dataCatalog',
  ADD_SOURCE_V1: '/addSourceV1',
  CONNECTION_DETAILS: (id = ':id') => `/connections/${id}`,
};

const Routes = () => {
  const location = useLocation<{ isSideMenuClick?: boolean }>();

  const {
    permissionsStore: { hasPermission },
    connectionsStore,
    featuresStore,
    sourcesListStore,
    destinationsListStore,
  } = useStores();

  const enableProfiles = featuresStore.has('PROFILES');
  const enableSqlModel = featuresStore.has('MODELS');
  const enableAudience = featuresStore.has('AUDIENCES');
  const enableTrackingPlans = featuresStore.has('TRACKING_PLAN');
  const enableEventMetrics = !featuresStore.has('DISABLE_REPORTING');
  const enableQuickstart = featuresStore.has('QUICKSTART');
  const enableInvoices = featuresStore.has('BILLING');
  const enableDataCatalog = featuresStore.has('DATA_CATALOG');
  const enableCreateTrackingPlan =
    enableDataCatalog && hasPermission({ resourceType: 'workspace', action: 'edit' });
  const enableAudienceCohort = experiment.isFeatureEnabled(FeatureFlagKeys.enableCohortAudience);
  const renderQuickstart =
    !isConnectionAvailable(connectionsStore.connections) &&
    enableQuickstart &&
    !location.state?.isSideMenuClick;

  const splitLocation = location.pathname.split('/');

  const authorizedRoutes = [
    {
      path: Path.HOME,
      component: React.useMemo(
        () => (renderQuickstart ? GetStarted : Connections),
        [renderQuickstart],
      ),
      enable: true,
    },
    { path: Path.GET_STARTED, component: GetStarted, enable: enableQuickstart },
    { path: Path.DIRECTORY, component: Directory, enable: true },
    { path: Path.PROFILE, component: Profile, enable: true },
    {
      path: Path.WORKSPACE,
      component: Workspace,
      enable:
        hasPermission({ resourceType: 'workspace', action: 'edit' }) ||
        featuresStore.has('DATA_MANAGEMENT') ||
        featuresStore.has('AUDIT_LOGS'),
    },
    { path: Path.ORGANIZATION, component: Organization, enable: true },
    { path: Path.SOURCES, component: SourcesListComponent, enable: true },
    {
      path: Path.CREATE_SOURCE,
      component: CreateSource,
      enable: hasPermission({ resourceType: 'source', action: 'create' }),
    },
    { path: Path.CANNY, component: Canny, enable: true },
    {
      path: '/sources/live/intro/:id',
      component: IntroPage,
      enable: hasPermission({ resourceType: 'workspace', action: 'pii' }),
    },
    {
      path: '/sources/live/:id',
      component: EventsView,
      enable: hasPermission({ resourceType: 'workspace', action: 'pii' }),
    },
    {
      path: Path.CONNECT_EXISTING_DESTINATION(':id'),
      component: ConnectExistingDestination,
      enable: hasPermission({
        resourceType: 'source',
        action: 'edit',
        resourceId: splitLocation[2],
      }),
    },
    {
      path: Path.CONNECT_NEW_DESTINATION(':id'),
      component: ConnectNewDestination,
      enable: hasPermission({
        resourceType: 'source',
        action: 'edit',
        resourceId: splitLocation[2],
      }),
    },
    {
      path: Path.SOURCE_DETAILS(),
      component: React.useMemo(
        () =>
          with404Redirect(SourceViewComponent, {
            exists: (id) => !!sourcesListStore.sourceById(id),
            redirectPath: Path.SOURCES,
          }),
        [],
      ),
      enable: true,
    },
    { path: Path.DESTINATIONS, component: Destinations, enable: true },
    {
      path: Path.CLONE_DESTINATION(':id'),
      component: CloneDestination,
      enable:
        hasPermission({
          resourceType: 'destination',
          action: 'edit',
          resourceId: splitLocation[2],
        }) && hasPermission({ resourceType: 'destination', action: 'create' }),
    },
    {
      path: Path.ADD_NEW_DESTINATION_REGION(':id'),
      component: AddNewDestinationRegion,
      enable: hasPermission({
        resourceType: 'destination',
        action: 'edit',
        resourceId: splitLocation[2],
      }),
    },
    {
      path: '/destinations/live/intro/:id',
      component: IntroPage,
      enable: hasPermission({ resourceType: 'workspace', action: 'pii' }),
    },
    {
      path: '/destinations/live/:id',
      component: StatusesView,
      enable: hasPermission({ resourceType: 'workspace', action: 'pii' }),
    },
    {
      path: Path.DESTINATION_DETAILS(),
      component: React.useMemo(
        () =>
          with404Redirect(DestinationDetails, {
            exists: (id) => !!destinationsListStore.destinationById(id),
            redirectPath: Path.DESTINATIONS,
          }),
        [],
      ),
      enable: true,
    },
    { path: Path.DESTINATION_MAPPING_CONFIG(), component: DestinationMappingConfig, enable: true },
    { path: Path.CONNECT_NEW_SOURCE(':destinationId'), component: ConnectNewSource, enable: true },
    {
      path: Path.CREATE_DESTINATION,
      component: CreateDestination,
      enable: hasPermission({ resourceType: 'destination', action: 'create' }),
    },
    {
      path: Path.CONNECT_EXISTING_SOURCE(':id'),
      component: ConnectExistingSource,
      enable: hasPermission({
        resourceType: 'destination',
        action: 'edit',
        resourceId: splitLocation[2],
      }),
    },
    { path: Path.TRANSFORMATIONS, component: TransformationsPage, enable: true },
    {
      path: Path.ADD_TRANSFORMATION,
      component: AddTransformation,
      enable: hasPermission({ resourceType: 'transformation', action: 'create' }),
    },
    {
      path: '/transformations/live/intro/:id',
      component: IntroPage,
      enable: hasPermission({ resourceType: 'workspace', action: 'pii' }),
    },
    {
      path: '/transformations/live/:id',
      component: EventTransformStatusesView,
      enable: hasPermission({ resourceType: 'workspace', action: 'pii' }),
    },
    { path: '/transformations/:id', component: TransformationDetails, enable: true },
    {
      path: '/libraries/add',
      component: AddLibrary,
      enable: hasPermission({ resourceType: 'transformation', action: 'create' }),
    },
    { path: '/libraries/:id', component: LibraryDetails, enable: true },
    { path: '/models', component: Models, enable: enableSqlModel },
    {
      path: Path.MODEL_DETAILS(),
      component: SourceViewComponent,
      enable: enableSqlModel,
    },
    {
      path: Path.AUDIENCE_CONNECT(),
      component: AudienceConnectDestination,
      enable: enableAudience && hasPermission({ resourceType: 'workspace', action: 'edit' }),
    },
    { path: Path.AUDIENCES, component: Audiences, enable: enableAudience },
    {
      path: Path.AUDIENCE_DETAILS(),
      component: SourceViewComponent,
      enable: enableAudience,
    },
    { path: Path.SYNCS, component: Syncs, enable: true },
    { path: '/syncs/:id', component: SyncDetail, enable: true },
    {
      path: Path.SETUP_TRACKING_PLAN,
      component: chooseTrackngPlan,
      enable: true,
    },
    {
      path: Path.TRACKING_PLANS_SETUP(':mode'),
      component: createTrackingPlans,
      enable: enableCreateTrackingPlan,
    },
    {
      path: Path.TRACKING_PLANS,
      component: TrackingPlanListComponent,
      enable: true,
    },
    {
      path: Path.TRACKING_PLAN_DETAILS(':id'),
      component: TrackingPlanViewComponent,
      enable: enableTrackingPlans,
    },
    {
      path: Path.LINK_NEW_SOURCE(':id'),
      component: LinkSourceComponent,
      enable:
        enableTrackingPlans &&
        sourcesListStore.sources.some((source) =>
          hasPermission({ resourceId: source.id, resourceType: 'source', action: 'edit' }),
        ),
    },
    {
      path: Path.LINK_TRACKING_PLAN(':id'),
      component: LinkTrackingPlanComponent,
      enable:
        enableTrackingPlans &&
        hasPermission({ resourceType: 'source', action: 'edit', resourceId: splitLocation[2] }),
    },
    {
      path: Path.HEALTH_DASHBOARD,
      component: HealthDashboard,
      enable: enableEventMetrics,
    },
    {
      path: Path.DATA_CATALOG,
      component: DataCatalog,
      enable: enableDataCatalog,
    },
    {
      path: Path.INVOICES,
      component: Invoices,
      enable: enableInvoices,
    },
    {
      path: Path.CONNECTION_DETAILS(':id'),
      component: RetlConnectionDetails,
      enable: true,
    },
  ];

  return (
    <StretchLayout>
      <Suspense
        fallback={
          <Skeleton>
            <h1>This is loading screen</h1>
          </Skeleton>
        }
      >
        <Switch>
          {authorizedRoutes.map(
            (route) =>
              route.enable && (
                <Route key={route.path} path={route.path} exact component={route.component} />
              ),
          )}

          {enableProfiles && (
            <Route key={Path.PROFILES} path={Path.PROFILES} component={Profiles} />
          )}

          {enableAudienceCohort && (
            <Route
              key={Path.COHORT_AUDIENCES}
              path={Path.COHORT_AUDIENCES}
              component={AudienceCohort}
            />
          )}

          <RETLWorkflowRoutes />

          <RedirectRoute />
        </Switch>
      </Suspense>
    </StretchLayout>
  );
};

/* eslint-disable import/no-default-export */
export default Routes;
