import { DefaultTheme } from 'styled-components';

export type PaddingVariant =
  | 'paddingXXS'
  | 'paddingXS'
  | 'paddingSM'
  | 'padding'
  | 'paddingMD'
  | 'paddingLG'
  | 'paddingXL'
  | 'paddingXXL'
  | 'padding3XL'
  | 'padding4XL'
  | 'padding5XL';

export type BorderRadiusVariant =
  | 'borderRadiusXS'
  | 'borderRadiusSM'
  | 'borderRadius'
  | 'borderRadiusLG'
  | 'borderRadiusXL'
  | 'borderRadiusXXL'
  | 'borderRadius3XL'
  | 'borderRadius4XL'
  | 'borderRadius5XL';

export type MarginVariant =
  | 'margin'
  | 'marginXXS'
  | 'marginXS'
  | 'marginSM'
  | 'marginMD'
  | 'marginLG'
  | 'marginXL'
  | 'marginXXL'
  | 'margin3XL'
  | 'margin4XL'
  | 'margin5XL';

export const createBorderRadiusVariant =
  (defaultValue?: BorderRadiusVariant) =>
  ({
    theme,
    borderRadius = defaultValue,
  }: {
    theme: DefaultTheme;
    borderRadius?: BorderRadiusVariant;
  }) =>
    !borderRadius ? '' : `border-radius: ${theme.token[borderRadius]}px;`;

export const createPaddingVariant =
  (defaultValue?: PaddingVariant) =>
  ({ theme, padding = defaultValue }: { theme: DefaultTheme; padding?: PaddingVariant }) =>
    !padding ? '' : `padding: ${theme.token[padding]}px;`;

export const createMarginVariant =
  (defaultValue?: MarginVariant, type: 'horizontal' | 'vertical' = 'vertical') =>
  ({ theme, margin = defaultValue }: { theme: DefaultTheme; margin?: MarginVariant }) =>
    !margin
      ? ''
      : `margin: ${
          type === 'horizontal' ? `0 ${theme.token[margin]}px` : `${theme.token[margin]}px 0`
        };`;
