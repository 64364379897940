import { action, makeObservable, observable } from 'mobx';
import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { RetlSourceStore } from './retlSource';
import { CreateRETLSourceRequest, RETLSource, RETLSourceType } from './types';
import { getPermissions } from '@stores/util';
import { Analytics } from '@lib/analytics';
import { ProfileSourcePayload } from '@components/profiles/activation/stores/types';
import { PROFILE_SOURCE_TYPES } from '../../components/profiles/activation/stores/types';

interface IRetlSourceListStore {
  sources: RetlSourceStore<RETLSourceType>[];
  firstLoad: boolean;
  createRetlSource: (
    payload: CreateRETLSourceRequest<RETLSourceType>,
  ) => Promise<string | undefined>;
  setRetlSources: (sources: RetlSourceStore<RETLSourceType>[]) => void;
  getRetlSources: () => Promise<void>;
  deleteRetlSource: (id: string) => Promise<boolean>;
  getRetlSourceById: <T extends RETLSourceType>(id: string) => RetlSourceStore<T> | undefined;
  reset: () => void;
  getRetlSourceByType: <T extends RETLSourceType>(type: T) => RetlSourceStore<T>[];
}

export class RetlSourceListStore implements IRetlSourceListStore {
  rootStore: IRootStore;

  @observable sources: RetlSourceStore<RETLSourceType>[] = [];

  @observable firstLoad = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  setRetlSources(sources: RetlSourceStore<RETLSourceType>[]) {
    this.sources = sources;
  }

  @action.bound
  public getSources(type?: RETLSourceType) {
    return this.sources.filter((source) => source.sourceType === type);
  }

  @action.bound
  async getRetlSources() {
    const { messagesStore, permissionsStore, retlConnectionListStore } = this.rootStore;

    try {
      const { data } = await apiAuthCaller().get<RETLSource<RETLSourceType>[]>('/retl-sources');
      const sources = data.map((source) => new RetlSourceStore(source, this.rootStore));
      this.setRetlSources(sources);
      retlConnectionListStore.updateRetlSourceWithDestinationIds();
      permissionsStore.registerResource(getPermissions(data));
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to get Retl-sources'));
    } finally {
      this.firstLoad = true;
    }
  }

  @action.bound
  async createRetlSource(
    payload: CreateRETLSourceRequest<RETLSourceType> | ProfileSourcePayload,
  ): Promise<string | undefined> {
    const { messagesStore, permissionsStore } = this.rootStore;
    try {
      const response = await apiAuthCaller().post<RETLSource<RETLSourceType>>(
        '/retl-sources',
        payload,
      );

      const savedRetlSource = new RetlSourceStore(response.data, this.rootStore);

      this.setRetlSources([savedRetlSource, ...this.sources]);
      Analytics.track({
        event: 'sourceCreated',
        properties: { sourceId: savedRetlSource.id, sourceType: savedRetlSource.sourceDef.name },
      });
      permissionsStore.addNewResource(savedRetlSource.id);
      return savedRetlSource.id;
    } catch (err) {
      messagesStore.showErrorMessage(getApiErrorMessage(err, 'Failed to create the Retl-source'));
      return undefined;
    }
  }

  @action.bound
  public getRetlSourceById = <T extends RETLSourceType>(id: string) =>
    this.sources.find((source) => source.id === id) as RetlSourceStore<T> | undefined;

  public getActivationSourcesByCohortId(id: string, entityId: string, cohortId: string) {
    const activationSources = this.sources.filter((source) => {
      const { config } = source as RETLSource<PROFILE_SOURCE_TYPES>;
      return config.whtProjectId === id && config.entity === entityId && config.cohort === cohortId;
    });
    return activationSources;
  }

  @action.bound
  async deleteRetlSource(
    sourceId: string,
    message?: {
      success: string;
      error: string;
    },
  ) {
    const { messagesStore } = this.rootStore;
    const retlSource = this.getRetlSourceById(sourceId)!;

    try {
      await apiAuthCaller().delete<void>(`/retl-sources/${sourceId}`);
      this.setRetlSources(this.sources.filter((source) => source.id !== sourceId));
      messagesStore.showSuccessMessage(message?.success ?? 'rETL source deleted successfully');

      Analytics.track({
        event: 'sourceDeleted',
        properties: { sourceId: retlSource.id, sourceType: retlSource?.sourceDefinitionName },
      });

      return true;
    } catch (err) {
      messagesStore.showErrorMessage(
        getApiErrorMessage(err, message?.error ?? 'Failed to delete rETL source'),
      );
      return false;
    }
  }

  @action.bound
  reset() {
    this.setRetlSources([]);
    this.firstLoad = false;
  }

  public getRetlSourceByType<T extends RETLSourceType>(type: T) {
    return this.sources.filter(
      (source): source is RetlSourceStore<T> => source.sourceType === type,
    );
  }
}
