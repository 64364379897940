import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import theme from '@css/theme';
import { ConfigProvider } from 'antd';
import { stores } from '@stores/index';
import { Provider } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from '@lib/bugsnag';
import { ErrorPage } from '@components/common/errorPage';
import AppRouter from './auth/appRouter';
import { lazyLoadedScripts } from './scripts';
import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_APP_ID, DATADOG_CLIENT_TOKEN } from '@config';
import DittoProvider from 'ditto-react';
import dittoData from './ditto';
import light from '@ui-library/tokens/light.json';
import GlobalStyles from './globalStyles';
import { init } from 'commandbar';
import { experiment, FeatureFlagKeys } from '@lib/experiment';

const isCommandBarEnabled = experiment.isFeatureEnabled(FeatureFlagKeys.enableCommandAI);
if (isCommandBarEnabled) {
  init('2d67e02a');
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <DittoProvider source={dittoData}>
      <ConfigProvider
        theme={{
          token: {
            ...light,
            fontFamily: `'Inter', 'Roboto', 'Noto Sans', 'Open Sans', 'Lato', 'Source Sans Pro', sans-serif`,
          },
          components: {
            Button: {
              borderRadius: light.borderRadiusXXL,
              borderRadiusLG: light.borderRadius3XL,
              borderRadiusSM: light.borderRadiusXXL,
              fontSizeLG: light.fontSize,
            },
            Table: {
              borderRadius: 0,
              borderRadiusLG: 0,
            },
            Segmented: {
              colorBgLayout: light.colorFillTertiary,
            },
          },
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Provider {...stores}>
            <div className="App" data-version={process.env.REACT_APP_VERSION}>
              <AppRouter />
            </div>
          </Provider>
        </ErrorBoundary>
      </ConfigProvider>
    </DittoProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

datadogRum.init({
  applicationId: DATADOG_APP_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'control-plane-webapp',
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
  sampleRate: 100,
  replaySampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

lazyLoadedScripts();
