import React, { useCallback, useState, ReactElement } from 'react';
import Modal from '@ui-library/modal/skeleton';
import ModalContent, { ModalProps } from '@ui-library/modal/custom';

const useModalView = (comp: ReactElement) => {
  const [showModal, setShowModal] = useState(false);
  const a = useCallback(
    (b: boolean) => {
      setShowModal(b);
    },
    [setShowModal],
  );
  let modal = null;
  if (showModal) {
    modal = (
      <Modal
        modalClosed={() => {
          setShowModal(false);
          comp.props?.onClose?.();
        }}
        show
      >
        {comp}
      </Modal>
    );
  }

  return [modal, a] as const;
};
export interface UseConfirmModalProps extends Omit<ModalProps, 'onClose'> {
  onClose?: () => void;
  closeOnSave?: boolean;
}
export const useConfirmModal = ({
  onClose,
  onSave,
  closeOnSave = true,
  ...restProps
}: UseConfirmModalProps) => {
  const [modal, showModal] = useModalView(
    <ModalContent
      size="md"
      saveLabelBtnType={{ type: 'primary' }}
      {...restProps}
      onSave={() => {
        onSave?.();
        if (closeOnSave) {
          showModal(false);
        }
      }}
      onClose={() => {
        showModal(false);
        onClose?.();
      }}
    />,
  );
  return [modal, showModal] as const;
};

/* eslint-disable import/no-default-export */
export default useModalView;
