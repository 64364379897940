import { DATA_CATALOG_API_PREFIX } from '@components/dataCatalog/constants';
import { AdvancedKeyword } from './createTrackingPlans/types';

export const TRACKING_PLAN_DOCS =
  'https://www.rudderstack.com/docs/data-governance/tracking-plans/';

export enum TRACKING_PLAN_TABS {
  Overview = 'Overview',
  Sources = 'Sources',
  Settings = 'Settings',
  Activity = 'Activity',
}

export const API_URLS = {
  templates: `${DATA_CATALOG_API_PREFIX}/templates`,
  templateRules: (templateId: string) => `${API_URLS.templates}/${templateId}`,
  trackingPlans: `${DATA_CATALOG_API_PREFIX}/tracking-plans`,
  singleTrackingPlan: (id: string) => `${API_URLS.trackingPlans}/${id}`,
  oldSingleTrackingPlan: (id: string) => `/trackingPlans/${id}`,
  trackingPlanEvents: (trackingPlanId: string) =>
    `${API_URLS.singleTrackingPlan(trackingPlanId)}/events`,
  singleTrackingPlanEvent: (trackingPlanId: string, eventId: string) =>
    `${API_URLS.trackingPlanEvents(trackingPlanId)}/${eventId}`,
  activityLogs: (id: string) => `${DATA_CATALOG_API_PREFIX}/tracking-plan-activity-logs/${id}`,
  migrateTrackingPlan: (id: string) => `${API_URLS.singleTrackingPlan(id)}/migrate`,
  copyTrackingPlan: (id: string) => `${API_URLS.singleTrackingPlan(id)}/copy`,
};

export const NESTED_PROPERTIES_DEPTH = 3;

export const OLD_TRACKING_PLAN_CREATION_TYPE = 'Google sheet/Tracking Plan API';

export const supportedAdvancedKeywords: AdvancedKeyword[] = [
  'enum',
  'format',
  'minimum',
  'maximum',
  'minLength',
  'maxLength',
  'multipleOf',
  'exclusiveMaximum',
  'exclusiveMinimum',
  'pattern',
  'uniqueItems',
  'minItems',
  'maxItems',
];
