import { Button } from '@ui-library/button';
import styled from 'styled-components';

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const CreateAccountButton = styled(Button)`
  margin-top: 16px;
`;

export const CenteredBlockLayout = styled.div`
  margin: auto;
  width: 75%;

  @media (min-width: 1000px) {
    width: 60%;
  }

  @media (min-width: 1500px) {
    width: 50%;
  }
`;
